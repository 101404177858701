import React from "react";
import projectLogoImage from "../assets/images/app-images/banner-cocktaily.png";
import v1MainImage from "../assets/images/app-images/cocktaily-v1-main.png";
import v2MainImage from "../assets/images/app-images/cocktaily-v2-main.png";
import v2OldFashioned from "../assets/images/app-images/cocktail-v2-old-fashioned.png";
import v3CocktailImage from "../assets/images/app-images/cocktaily-v3-cocktail-image.png";
import v3RecipeCode from "../assets/images/app-images/cocktaily-v3-code.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

let obj = `{
  "cocktailName": "Margarita",
  "recipeIngredients":
  [
    "2 oz Tequila",
    "1 oz Lime juice",
    "1 oz Orange liqueur",
    "Salt for rim",
    "Lime wedge for garnish"
  ],
  "recipeInstructions":
  "Rub the rim of the glass with
  the lime slice to make the salt stick to 
  it. Take a shaker, fill it with ice, 
  tequila, lime juice, and orange 
  liqueur. Shake well and strain into 
  the glass. Garnish with a lime wedge.",
  "minTemp": "70°F",
  "maxTemp": "95°F",
  "image": "margarita.png"
}`;

export default function Blog2() {
  return (
    <div className="pb-2 mt-8 w-full flex flex-col justify-center items-center">
      <section className="mx-auto max-w-screen-md w-11/12 rounded-2xl">
        <div className="flex flex-col justify-center items-center">
          <div className="container flex flex-col">
            <img
              className="content-center w-full rounded-xl"
              src={projectLogoImage}
            />
            <div className="w-full flex justify-center items-center">
              <button
                className="w-32 h-12 m-6 bg-zinc-100 text-lg font-semibold"
                onClick={() =>
                  window.open("https://poetic-faloodeh-74c81c.netlify.app/")
                }
              >
                <FontAwesomeIcon
                  className="h-8 text-zinc-800 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                  icon={faExternalLink}
                  color="white"
                />{" "}
              </button>
              <button
                className="w-32 h-12 m-6 bg-zinc-100 text-lg font-semibold"
                onClick={() =>
                  window.open(
                    "https://github.com/MattHlav478/cocktail-react"
                  )
                }
              >
                <FontAwesomeIcon
                  className="h-10 text-zinc-800 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                  icon={faGithub}
                  color="white"
                />{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="flex justify-center mb-6 text-pretty">
          <p className="w-1/2 sm:w-full text-left sm:text-center">
            <b>tldr;</b> a clunky group project reimagined into a bespoke solo
            debut{" "}
          </p>
        </div>
        <div className="text-2xl font-normal mb-2">
          It all started as a group project...
        </div>
        <p className="text-justify mb-4">
          During my coding program, our first group project’s goal was to “build
          a website using HTML, CSS, and Javascript that integrated at least one
          third-party API.” We decided on an app that would generate a cocktail
          recipe based on a user’s current weather.
        </p>
        <p className="text-justify mb-4">
          This required three APIs: one to find the weather, one to find the
          cocktail recipe based on some internal logic for those weather
          conditions, and the third to find a cocktail image.
        </p>
        <p className="text-justify mb-4">
          We didn’t have a headache with the weather API, but the others were
          far from ideal. The cocktail API wasn’t always reliable, and the image
          API needed serious babysitting.
        </p>
        <p className="text-justify mb-4">
          But, for the purpose of getting an MVP by due date (working as a new
          team on our first ever app), these *free* APIs had to do. We got an A.
        </p>
        <div className="flex flex-col justify-center items-center">
          <img
            className="content-center w-2/3 sm:w-64 mt-6 mb-2 rounded-xl"
            src={v1MainImage}
          />
          <div className="text-sm mb-4">
            V1: <s>fugly</s> inelegant, but we were proud
          </div>
        </div>
      </section>
      <section className="mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="text-2xl font-normal mb-2">
          Striking out (literally) solo
        </div>
        <p className="text-justify mb-4">
          After ending my coding program, I realized two things: 1) this app was
          worth my time, but 2) I couldn’t stand to look at it. I set out to
          revamp the aesthetic using Tailwind CSS as my primary tool for
          restyling the HTML elements. I went quickly, using ChatGPT and
          modifying its generated code (helpful, but not optimal), but the fact
          remained: the free image API generation was at best, the wrong size;
          at worst, generating a clipart margarita titled “Old Fashioned”.
        </p>
        <p className="text-justify mb-4">
          Using React JS as my framework, I began rebuilding. However, my desire
          to keep as much of the existing logic still held me hostage to the
          existing APIs. It was time to let go and get honest.
        </p>
        <p className="text-justify mb-4">
          Meanwhile, the weather API had built-in icons to the weather
          status—clouds, rain, sun—but frankly, they were ugly too! So, I built
          logic to associate the API’s icon codes to an icon set I collected
          using Font Awesome. The biggest issue was still the image search, but
          how could I divorce it from the cocktail recipe API as its search
          criteria? <b>The answer was to get rid of both.</b>
        </p>
        <div className="sm:flex sm:justify-around mx-auto sm:w-4/5">
          <div className="flex flex-col sm:w-64 justify-center items-center">
            <img
              className="content-center w-2/3 sm:w-full mt-6 mb-2 rounded-xl"
              src={v2MainImage}
            />
            <div className="text-sm mb-4">V2: putting lipstick on a pig</div>
          </div>
          <div className="flex flex-col sm:w-64 justify-center items-center">
            <img
              className="content-center w-2/3 sm:w-full mt-6 mb-2 rounded-xl"
              src={v2OldFashioned}
            />
            <div className="text-sm mb-4">
              Image Search API return for an "Old Fashioned" ::sigh::
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="text-2xl font-normal mb-2">The Overhaul</div>
        <p className="text-justify mb-4">
          I realized the most straightforward approach was to build my own
          collection of cocktail recipes with my own image creations. Using Open
          AI’s ChatGPT, I built a recipe database in JSON format. Then, I used
          Open AI’s DALL-E to generate images that, while still limited, at
          least appear cohesive. Finally, I analyzed a favorite image for a
          color palette and bam: we have a good lookin’ pig!{" "}
        </p>
        <div className="flex flex-col justify-around mx-auto sm:w-4/5">
          <div className="flex flex-col justify-center items-center">
            <img
              className="content-center sm:w-full mt-6 mb-2 rounded-xl"
              src={v3RecipeCode}
            />
            <div className="text-sm text-center mb-4">
              V3: example of cocktail JSON object
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <img
              className="content-center w-2/3 sm:w-64 mt-6 mb-2 rounded-xl"
              src={v3CocktailImage}
            />
            <div className="text-sm mb-4">Current Version: I'd drink that.</div>
          </div>
        </div>
      </section>

      <section className="mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="text-2xl font-normal mb-2">Some truths I learned</div>
        <ol className="list-outside">
          <li className="">
            No matter what “fancy” code I’ve got going, if it’s ugly, it’s over.
          </li>
          <li>Open AI tools are truly powerful, and not to be sneered at.</li>
          <li>Older standbys, like Font Awesome, still meet my needs.</li>
        </ol>
      </section>
      <section className="mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div>
          <div className="text-2xl font-normal mb-2">
            What I would do with money and a team
          </div>
          <ol className="list-outside">
            <li className="">
              I’d engage in the UX/UI testing and feedback cycle with real users
              and a design team.
            </li>
            <li>
              I would investigate higher quality APIs—weather, cocktail,
              image—to offload the work of maintaining my own data set.
            </li>
            <li>
              I would create a backend so users could save their recipes and
              submit new ones for their favorite rainy-day cocktails.
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
}
